import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

const styles = theme => ({
    root: {
        textAlign: "center",
        
        "& iframe": {
            textAlign: "center",
            borderRadius: 15,
            minHeight: "300px",
            height: "30vh",
            width: "80%",

            [theme.breakpoints.up("md")]: {
                width: "100%"
            }
        }
    }
})

function Video({classes, location}) {
    if(location.pathname !== "/" && !location.pathname.includes("/download")) return null;

    return (
        <div className={classes.root}>
            <iframe
                title="Azur Cosplay"
                src="https://www.youtube.com/embed/uo_mm9CEwck"
                frameBorder="0"
                allowFullScreen="allowfullscreen"
                mozallowfullscreen="mozallowfullscreen" 
                msallowfullscreen="msallowfullscreen" 
                oallowfullscreen="oallowfullscreen" 
                webkitallowfullscreen="webkitallowfullscreen"
            />
        </div>
    );
}


export default withRouter(withStyles(styles)(Video));