import React from "react";
import { Typography } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { withStyles } from "@material-ui/core/styles";

const styles = {
    root: {
        textAlign: "center",
        marginBottom: 16
    },

    link: {
        color: "lightblue"
    }
};

function Download({ classes, referralCode }) {
    return (
        <div className={classes.root}>
            <div>
                <Typography variant="h6">
                    Azur Cosplay is not freeware
                </Typography>
            </div>

            <div>
                <Typography>
                    Azur Cosplay costs $5 (USD) monthly.
                </Typography>
                <Typography>
                    To purchase Azur Cosplay visit <Link href="https://patreon.com/AzurCosplay" target="_blank" className={classes.link}>our patreon</Link>.{" "}
                    After getting the patreon subscription, <Link href="https://support.patreon.com/hc/en-us/articles/212052266-Getting-Discord-access" target="_blank" className={classes.link}>link your discord to patreon</Link>.{" "}
                    Finally join the <Link href="https://discord.gg/sT9ZKew" target="_blank" className={classes.link}>discord</Link> to download the mod!
                </Typography>
            </div>
        </div>
    );
}

export default withStyles(styles)(Download);
