import React from "react";
import { Typography, Divider, Link } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = {
    root: {
        textAlign: "center"
    },
    mainContentContainer: {
        margin: "16px 0"
    },

    link: {
        color: "lightblue"
    }
};

function Home({ classes }) {
    return (
        <div className={classes.root}>
            <div>
                <Typography variant="h4">Azur Cosplay</Typography>
            </div>
            <Divider />

            <div className={classes.mainContentContainer}>
                <Typography paragraph>
                    Azur Cosplay is a mod for the mobile game Azur Lane. It
                    allows you to use any skin and ship in game. Join our{" "}
                    <Link href="https://discord.gg/sT9ZKew" target="_blank" className={classes.link}>
                        discord
                    </Link>{" "}
                    for any questions.
                </Typography>
            </div>
        </div>
    );
}

export default withStyles(styles)(Home);
