import React from "react";

import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from "react-router-dom";
import Nav from "./components/Nav";
import { makeStyles } from "@material-ui/core";
import AlignRightContainer from "./components/AlignRightContainer";
import Video from "./components/Video";

import Home from "./pages/Home";
import Download from "./pages/Download";
import FAQ from "./pages/FAQ";
import Referral from "./pages/Referral";

const measurements = {
    outerCC: {
        padding: "5px",
        borderRadius: "30px"
    },

    innerCC: {
        padding: "20px",
        borderRadius: "25px"
    }
}

const useStyles = makeStyles(theme=> ({
    outerContentContainer: {
        backgroundColor: "rgba(52, 55, 57, 0.8)",
        flex: "0 1 500px",
        ...measurements.outerCC
    },

    innerContentContainer: {
        backgroundColor: "rgba(24, 26, 27, 0.4)",
        color: "white",
        ...measurements.innerCC
    },

    contentContainer: {
        textAlign: "center",
        width: "100%",
        overflow: "auto",
        maxHeight: "75vh"
    }
}))

function InvalidPage() {
    return <Redirect to="/" />;
}

function ContentContainer({ contentRef, children }) {
    const classes = useStyles();
    const containerRef = React.useRef(null);

    React.useEffect(() => {
        const maxHeight = `calc(100vh - ${contentRef.current.offsetHeight}px - ${measurements.outerCC.padding} - ${measurements.outerCC.borderRadius} - ${measurements.innerCC.padding})`;
        containerRef.current.style.maxHeight = maxHeight;
    }, [containerRef, contentRef]);

    return (
        <div className={classes.outerContentContainer}>
            <div className={classes.innerContentContainer}>
                <div className={classes.contentContainer} ref={containerRef}>
                    {children}
                </div>
            </div>
        </div>
    );
}

let referralCode = "";
function App() {
    const contentRef = React.useRef(null);

    { // handle referral code
        const search = new URLSearchParams(window.location.search);
        const value = search.get("rfc");

        if(value) {
            referralCode = value;
        }
    }

    return (
        <Router>
            <div ref={contentRef}>
                <header>
                    <Nav />
                </header>
            </div>

            <main style={{ display: "block" }}>
                <AlignRightContainer>
                    <ContentContainer contentRef={contentRef}>
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route path="/download" component={(props)=> <Download {...props} referralCode={referralCode} />} />
                            <Route path="/referral" component={Referral} />
                            <Route path="/faq" component={FAQ} />
                            <Route component={InvalidPage} />
                        </Switch>
                        <Video />
                    </ContentContainer>
                </AlignRightContainer>
            </main>
        </Router>
    );
}

export default App;
