import React from "react";
import { Typography, Link } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = {
    root: {
        textAlign: "center"
    },

    table: {
        marginTop: 16,
        width: "100%",

        "& th:nth-child(1), & td:nth-child(1)": {
            borderRight: "1px solid gray"
        },

        "& th, & td": {
            padding: "5px 10px 0",
            borderTop: "1px solid gray"
        }
    },

    link: {
        color: "lightblue"
    }
};

function Questions({ questions }) {
    questions.push({
        question: "",
        answer: ""
    });
    return (
        <>
            {questions.map(question => (
                <tr key={question.question}>
                    <td>{question.question}</td>
                    <td>{question.answer}</td>
                </tr>
            ))}
        </>
    );
}

function FAQ({ classes }) {
    return (
        <div className={classes.root}>
            <div>
                <Typography variant="h5">Frequently Asked Questions</Typography>
            </div>

            <table className={classes.table}>
                <thead>
                    <tr>
                        <th>Question</th>
                        <th>Answer</th>
                    </tr>
                </thead>

                <tbody>
                    <Questions
                        questions={[
                            {
                                question: "Does this work for all regions?",
                                answer: "Yes"
                            },
                            {
                                question: "Has anyone ever been banned?",
                                answer: "No"
                            },
                            {
                                question: "Will there ever be mobile support?",
                                answer: "Most likely not"
                            },
                            {
                                question: "How to contact you?",
                                answer: (
                                    <Link
                                        href="https://discord.gg/sT9ZKew"
                                        target="_blank"
                                        className={classes.link}
                                    >
                                        Discord
                                    </Link>
                                )
                            }
                        ]}
                    />
                </tbody>
            </table>
        </div>
    );
}

export default withStyles(styles)(FAQ);
