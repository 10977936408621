import React, { useState } from "react";
import { Button, Input, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = {
    root: {
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },

    buttonContainer: {
        margin: 16,
        color: "lightblue"
    },

    link: {
        color: "lightblue"
    },

    form: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
    },

    input: {
        color: "white",
        width: "70%",
        marginTop: 16,
    },

    button: {
        marginTop: 16,
        marginBottom: 8,
    },
};

function Referral({ classes }) {
    const [email, setEmail] = useState("");
    const [referralCode, setReferralCode] = useState(null);

    return (
        <div className={classes.root}>
            <div>
                <Typography variant="h6">
                    Refer a friend!
                </Typography>
            </div>

            <div>
                <Typography>
                    For every friend that buys Azur Cosplay with your referral link, you'll get $3!
                </Typography>

                    <Typography>
                    Referral money is paid out every quarter and is sent to the email that was used to generate the referral code.
                    </Typography>
            </div>

            <form className={classes.form} onSubmit={async (e)=> {
                e.preventDefault();

                try {
                    const { referralCode } = await (await fetch(`https://www.api.azur-cosplay.com/referral?email=${email}`)).json();
                    setReferralCode(referralCode);
                }catch(err) {
                    console.error(err);
                }
            }}>
                <Input
                    className={classes.input}
                    value={email}
                    color="primary"
                    placeholder="azur.cosplay@gmail.com"
                    onChange={(e)=> setEmail(e.currentTarget.value)}
                />

                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    type="submit"
                >Get referral code</Button>
            </form>
            
            {referralCode !== null && (
                <div>
                    <Typography>
                        Your referral code is {referralCode}
                    </Typography>

                    <Typography>
                        Refer friends with <a className={classes.link} href={`https://www.azur-cosplay.com?rfc=${referralCode}`}>https://www.azur-cosplay.com?rfc={referralCode}</a>
                    </Typography>
                </div>
            )}
        </div>
    );
}

export default withStyles(styles)(Referral);
