import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
    root: {
        display: "flex"
    },

    left: {
        flexGrow: 1,

        [theme.breakpoints.up("md")]: {
            flexGrow: 10
        }
    },

    right: {
        flexGrow: 1
    }
});

function AlignRightContainer({classes, children}) {
    return (
        <div className={classes.root}>
            <div className={classes.left}></div>
            {children}
            <div className={classes.right}></div>
        </div>
    );
}

export default withStyles(styles)(AlignRightContainer);
