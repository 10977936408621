import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Tabs, Tab, Paper } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import AlignRightContainer from "./AlignRightContainer";

const styles = theme => ({
    root: {
        textAlign: "center",
        paddingTop: 24,
        paddingBottom: "3vh",

        "@media (min-height:600px)": {
            paddingTop: "10vh"
        }
    },

    paper: {
        width: "max-content",
        background: "rgba(59, 50, 51, 0.4)",
        boxShadow: "none"
    },

    tab: {
        opacity: 1,
        color: "white"
    }
});

const LocationToNavIndex = {
    "/": 0,
    "/download": 1,
    "/download/": 1,
    "/faq": 2,
    "/faq/": 2
};
const ReverseLocationToNavIndex = Object.entries(LocationToNavIndex).reduce(
    (acc, [path, idx]) => {
        acc[idx] = path;
        return acc;
    },
    {}
);

function getIndexForLocation(location) {
    return LocationToNavIndex[location.pathname] || 0;
}

function Nav({ classes, location, history }) {
    const [index, setIndex] = React.useState(getIndexForLocation(location));

    const onTabChange = (_, newIndex) => {
        setIndex(newIndex);
        history.push(ReverseLocationToNavIndex[newIndex]);
    };

    return (
        <div className={classes.root}>
            <AlignRightContainer>
                <Paper className={classes.paper}>
                    <Tabs
                        value={index}
                        onChange={onTabChange}
                        aria-label="Navigation bar"
                    >
                        <Tab
                            label="Home"
                            className={classes.tab}
                            id="navigate-home"
                            aria-controls="navigate-home"
                        />
                        <Tab
                            label="Download"
                            className={classes.tab}
                            id="navigate-download"
                            aria-controls="navigate-download"
                        />
                        <Tab
                            label="FAQ"
                            className={classes.tab}
                            id="navigate-faq"
                            aria-controls="navigate-faq"
                        />
                    </Tabs>
                </Paper>
            </AlignRightContainer>
        </div>
    );
}

export default withRouter(withStyles(styles)(Nav));
